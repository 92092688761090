<template>
  <el-table :data="operators">
    <el-table-column prop="id_operator" label="ID" width="200" />
    <el-table-column prop="operator_name" label="Название">
      <template slot-scope="scope">
        <router-link
          class="link"
          :to="{
            name: 'operators:edit',
            params: { id: scope.row.id_operator },
          }"
        >
          {{ scope.row.operator_name }}
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListOperators",
  computed: {
    ...mapState("servers", {
      operators: (state) => state.operators,
    }),
  },
};
</script>
