<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-tabs>
          <el-tab-pane label="Прокси-сервер">
            <el-form ref="form" :rules="rules" :model="form">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Название" prop="name">
                    <el-input v-model="form.name" size="small" placeholder="Название" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Домен" prop="domain_name">
                    <el-input v-model="form.domain_name" size="small" placeholder="Домен" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ID" prop="server_id">
                    <el-input v-model="form.server_id" size="small" placeholder="ID" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="Количество камер" prop="cameras_count">
                    <el-input-number id="cameras_count" v-model="form.cameras_count" :min="0" size="small" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Управление через AVC панель" prop="is_managed_by_avc_panel">
                    <el-switch
                      v-model="form.is_managed_by_avc_panel"
                      :active-value="1"
                      :inactive-value="0"
                      :disabled="isSwitchBlocked"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="API-ключ AVC панели" prop="avc_panel_api_key">
                    <el-input v-model="form.avc_panel_api_key" size="small" placeholder="api_key" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Группа серверов" prop="id_server_group">
                    <template slot="label">
                      Группа серверов
                    </template>
                    <el-tooltip class="item" effect="light" placement="top-start">
                      <template slot="content">
                        Группа влияет на <code>rules.conf</code> сервера. Оставить пустым для настроек по умолчанию
                      </template>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    <el-select
                      v-model="form.id_server_group"
                      size="small"
                      placeholder="Нет группы"
                      style="width: 100%"
                      clearable
                    >
                      <el-option
                        v-for="val in serverGroups"
                        :key="val.id_server_group"
                        :label="val.server_group_name"
                        :value="val.id_server_group"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="Операторы">
            <list-operators />
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-button class="page__active_button" type="primary" :loading="fetch" @click="submit">
            Сохранить
          </el-button>
        </el-col>
      </el-row>
    </el-footer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { length, required } from "@/core/helpers/validators";
import ListOperators from "./ListOperators";

export default {
  name: "ServerEdit",
  components: {
    ListOperators,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fetch: false,
      isSwitchBlocked: false,
      serverGroups: [],
      rules: {
        name: [required(), length(3, 200)],
        domain_name: [required(), length(3, 200)],
        server_id: [required()],
        cameras_count: [required("number")],
        is_managed_by_avc_panel: [required("number")],
      },
    };
  },
  computed: {
    ...mapState("servers", {
      form: (state) => state.server,
    }),
  },
  created() {
    this.getServerGroups()
      .then((res) =>
        this.serverGroups = res
      );
    this.getServer(this.id)
      .then(() =>
        this.isSwitchBlocked = this.form.is_managed_by_avc_panel === 1
      );
  },
  methods: {
    ...mapActions("servers", {
      getServerGroups: "GET_SERVER_GROUPS",
      getServer: "GET_SERVER",
      updateServer: "UPDATE_SERVER",
    }),
    submit() {
      this.$confirm("Вы точно хотите сохранить изменения?", "Подтверждение", {
        confirmButtonText: "Сохранить",
        type: "warning",
      })
        .then(() => {
          let formIsValid;
          this.$refs["form"].validate((status) => {
            formIsValid = status;
          });
          if (!formIsValid) return false;

          this.loading = true;
          const newServer = Object.assign({}, this.form);
          const id = parseInt(this.id, 10);
          this.updateServer({ id, newServer })
            .then(() => {
              this.$router.push({ name: "servers", params: { page: 1 } });
              this.$message({
                message: "Изменения сохранены",
                type: "success",
              });
            })
            .catch(({ response }) => {
              this.$message({
                message: response.data.error,
                type: "error",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Отмена",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.page_container {
  padding: 20px;
}
</style>
